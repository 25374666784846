var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reg-consultant-detail"
  }, [_c('div', {
    staticClass: "heart-box"
  }, [_vm.showLike ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.editLike(_vm.consultantDetail.hb_id, false);
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "like-icon",
    attrs: {
      "icon": "heart"
    }
  })], 1) : _c('div', {
    on: {
      "click": function ($event) {
        return _vm.editLike(_vm.consultantDetail.hb_id, true);
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "unlike-icon",
    attrs: {
      "icon": ['far', 'heart']
    }
  })], 1)]), _c('div', {
    staticClass: "reminders"
  }, [_vm.showReminders ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.closeVipRemindConsultant();
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "reminders-icon",
    attrs: {
      "icon": "bell"
    }
  })], 1) : _c('div', {
    on: {
      "click": function ($event) {
        return _vm.openVipRemindConsultant();
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "unreminders-icon",
    attrs: {
      "icon": "bell"
    }
  })], 1)]), _c('skeleton', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('template', {
    slot: "skeleton-template"
  }, [_c('skeleton-template')], 1), _c('template', {
    slot: "origin-template"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "are-consultant-picture"
  }, [_c('img', {
    staticClass: "img-consultant-pictrue",
    attrs: {
      "src": _vm.consultantDetail.picture,
      "alt": _vm.consultantDetail.name,
      "title": _vm.consultantDetail.name
    }
  })])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('h2', {
    staticClass: "h2-consultant-name"
  }, [_vm._v(_vm._s(_vm.consultantDetail.name))]), _c('div', {
    staticClass: "row consultant-title"
  }, [_c('b-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('div', {
    staticClass: "txt-consultant-title"
  }, [_vm._v(_vm._s(_vm.$t('from')))])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "11"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.consultantDetail.nationality))])])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.consultantDetail.score > 60,
      expression: "consultantDetail.score > 60"
    }],
    staticClass: "row consultant-title"
  }, [_c('b-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('div', {
    staticClass: "txt-consultant-title"
  }, [_vm._v(_vm._s(_vm.$t('teacherScore')))])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "11"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.consultantDetail.score))])])], 1)])], 1), _c('p', {
    staticClass: "txt-consultant-introduction"
  }, [_c('span', {
    staticClass: "txt-consultant-title"
  }, [_vm._v(_vm._s(_vm.$t('introduction')))]), _c('span', [_vm._v(_vm._s(_vm.consultantDetail.self_introduction))])]), _c('p', {
    staticClass: "txt-consultant-suggestion"
  }, [_c('span', {
    staticClass: "txt-consultant-title"
  }, [_vm._v(_vm._s(_vm.$t('suggestion')))]), _c('span', [_vm._v(_vm._s(_vm.consultantDetail.suggestion))])]), _c('b-row', [_vm.consultantDetail.self_introduction_url_path ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t('introduction')) + _vm._s(_vm.$t('video')))]), _c('b-embed', {
    attrs: {
      "src": _vm.consultantDetail.self_introduction_url_path,
      "allowfullscreen": ""
    }
  })], 1) : _vm._e(), _vm.consultantDetail.teaching_url_path ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t('teach')) + _vm._s(_vm.$t('video')))]), _c('b-embed', {
    attrs: {
      "src": _vm.consultantDetail.teaching_url_path,
      "allowfullscreen": ""
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "are-consultant-control"
  }, [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCalendarBtn,
      expression: "showCalendarBtn"
    }],
    staticClass: "btn btn-outline-primary btn-consultant-schedule",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function () {
        _this.$router.push({
          name: 'requireConsultantSchedule',
          params: {
            hbConsultantId: _this.$route.params.id
          }
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon icon-calendar"
  }), _c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'calendar']
    }
  }), _vm._v(_vm._s(_vm.$t('calendar')))], 1), _c('button', {
    staticClass: "btn btn-outline-primary btn-consultant-back",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_vm._v("返 回")])])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }