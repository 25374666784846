export default {
  GET_TYPE: { // 取得顧問列表的方式
    ALL: 'all', // 取得所有的顧問列表
    LIKE: 'like', // 取得喜歡的顧問
    SEARCH: 'search', // 搜尋條件取得顧問
    CHANG_PAGE: 'changePage', // 更改頁數的取得顧問
  },
  API_PARAM: {
    PLAT_FORM_WUWOW: 1, // 打API需要帶的參數 平台 WUWOW寫死1
    PLAT_FORM_JR: 2, // 打API需要帶的參數 平台 WUWOW寫死1
    TYPE_ORDER: 'order', // order: 有選其他參數時的type
  },
  CONSULTANT_LIKE: 'like', // 是否喜歡該顧問
};
