<template lang="pug">
div
  .row
    .col-3
      .are-consultant-picture
        b-skeleton(type="avatar")
    .col-9
      h2.h2-consultant-name
        b-skeleton(width="20%")
      .row.consultant-title
        .col-1
          .txt-consultant-title {{ $t('from') }}
        .col-11
          b-skeleton(width="30%")
  p.txt-consultant-introduction
    span.txt-consultant-title {{ $t('introduction') }}
    b-skeleton(width="100%")
  p.txt-consultant-suggestion
    span.txt-consultant-title {{ $t('suggestion') }}
    b-skeleton(width="100%")
  .are-consultant-control
    b-skeleton(type="button")
</template>

<script type="text/javascript">
import { consultantDetailLang } from '@/components/vip/consultantList/detail/lang';

export default {
  name: 'ConsultantDetailSkeleton',

  i18n: consultantDetailLang,
};
</script>
