<!-- eslint-disable space-in-parens -->
<template lang="pug">
  .reg-consultant-detail
    //- 喜愛顧問 愛心
    .heart-box
      //- // 移除LIKE
      div(v-if='showLike' @click='editLike(consultantDetail.hb_id, false)')
        font-awesome-icon.like-icon(icon="heart")
      //- // 設定LIKE
      div(v-else @click='editLike(consultantDetail.hb_id, true)')
        font-awesome-icon.unlike-icon(:icon="['far', 'heart']")
    .reminders
      div(v-if="showReminders" @click="closeVipRemindConsultant()")
        font-awesome-icon.reminders-icon(icon="bell")
      div(v-else @click="openVipRemindConsultant()")
        font-awesome-icon.unreminders-icon(icon="bell")
    skeleton(:loading="loading")
      template(slot="skeleton-template")
        skeleton-template
      template(slot="origin-template")
        b-row
          b-col(cols="12" md="3").d-flex.justify-content-center
            .are-consultant-picture
              img.img-consultant-pictrue(:src='consultantDetail.picture' :alt='consultantDetail.name' :title='consultantDetail.name')
          b-col(cols="12" md="9")
            h2.h2-consultant-name {{ consultantDetail.name }}
            .row.consultant-title
              b-col(cols="1" )
                .txt-consultant-title {{ $t('from') }}
              b-col(cols="12" md="11")
                div {{ consultantDetail.nationality }}
            .row.consultant-title(v-show='consultantDetail.score > 60')
              b-col(cols="1")
                .txt-consultant-title {{ $t('teacherScore') }}
              b-col(cols="12" md="11")
                div {{ consultantDetail.score }}
        p.txt-consultant-introduction
          span.txt-consultant-title {{ $t('introduction') }}
          span {{ consultantDetail.self_introduction }}
        p.txt-consultant-suggestion
          span.txt-consultant-title {{ $t('suggestion') }}
          span {{ consultantDetail.suggestion }}
        b-row
          b-col.mt-2(v-if="consultantDetail.self_introduction_url_path" cols="12" md="6")
            h4 {{ $t('introduction') }}{{ $t('video') }}
            b-embed(:src="consultantDetail.self_introduction_url_path" allowfullscreen)
          b-col.mt-2(v-if="consultantDetail.teaching_url_path" cols="12" md="6")
            h4 {{ $t('teach') }}{{ $t('video') }}
            b-embed(:src="consultantDetail.teaching_url_path" allowfullscreen)
        .are-consultant-control
          button.btn.btn-outline-primary.btn-consultant-schedule(v-show='showCalendarBtn' type='button' @click="() => { this.$router.push({ name: 'requireConsultantSchedule', params: { hbConsultantId: this.$route.params.id } }); }")
            i.icon.icon-calendar
            font-awesome-icon.mr-2(:icon="['far', 'calendar']")
            | {{ $t('calendar') }}
          button.btn.btn-outline-primary.btn-consultant-back(type='button' @click='back()') 返 回
</template>

<script type="text/javascript">
import lioshutanApi from '@/api';
import { consultantDetailLang } from '@/components/vip/consultantList/detail/lang';
import skeleton from '@/components/skeleton/index.vue';
import skeletonTemplate from '@/components/vip/consultantList/detail/skeleton/index.vue';
import consultantsListConstant from '@/constants/consultantsList.js';
import { debounce } from 'lodash';
import swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

// 暫時放的假資料
// import consultants from '../../../../../public/consultants.json';

export default {
  name: 'ConsultantDetail',

  components: {
    skeleton,
    skeletonTemplate,
  },

  i18n: consultantDetailLang,

  data() {
    return {
      loading: false,
      showLike: false,
      showReminders: false,
      likeEditable: true,
      consultantDetail: {},
      consultantBindRemendId: '',
      openVipRemindConsultant: debounce(this.createVipRemindConsultant, 300),
      closeVipRemindConsultant: debounce(this.delVipRemindConsultant, 300),
    };
  },

  computed: {
    showCalendarBtn: function() {
      return this.setShowCalendarBtn();
    },
  },

  watch: {},

  async created() {
    this.startLoading();
    const response = await lioshutanApi.personnel.queryConsultantDetail(this.$route.params.id);
    const preference = response.data.data.hb_profile.preference;
    this.showLike = preference === 1;
    this.consultantDetail = await response.data.data.hb_profile;
    this.endLoading();
    await this.getVipRemindConsultant();
  },

  methods: {
    async getVipRemindConsultant() {
      const res = await lioshutanApi.vip.getVipRemindConsultant({
        hbConsultantIds: [this.$route.params.id],
      });
      if (res.data.data) {
        const { status, id } = res.data.data[this.$route.params.id];
        this.showReminders = !!status;
        this.consultantBindRemendId = id;
      }
    },
    async createVipRemindConsultant() {
      try {
        await lioshutanApi.vip.createVipRemindConsultant({
          hbConsultantId: [this.$route.params.id],
        });
        await this.getVipRemindConsultant();
        await swal.fire({
          title: '顧問授課時間通知設定成功',
          text: '您已設定此顧問新增授課時間通知，請確認是否已完成綁定課程通知(Line notify)，謝謝您',
          icon: 'success',
        });
      } catch (error) {
        console.log(error);
      }
    },
    async delVipRemindConsultant() {
      try {
        await lioshutanApi.vip.delVipRemindConsultant(this.consultantBindRemendId);
        await this.getVipRemindConsultant();
        // await swal.fire({
        //   title: '顧問授課時間不通知設定成功',
        //   text: '您已設定此顧問新增授課時間通知，請確認是否已完成綁定課程通知(Line notify)，謝謝您',
        //   icon: 'success',
        // });
      } catch (error) {
        console.log(error);
      }
    },
    startLoading(){
      this.loading = true;
    },
    endLoading(){
      this.loading = false;
    },
    setShowCalendarBtn(){
      if (this.consultantDetail.is_pb === 0) {
        return false;
      } else if (this.consultantDetail.name) {
        const isPM = this.consultantDetail.name.indexOf('PM_');
        if (isPM === 0) {
          return false;
        } else {
          return true;
        }
      }
    },
    back(){
      // 使用者有可能右鍵開啟，所以要判斷是否有上一頁，沒有上一頁就強制帶到顧問列表
      if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push({ name: 'consultants' });
      }
    },
    async editLike(id, status) {
      this.showLike = status;
      // 設置不要讓使用者連點
      if (this.likeEditable) {
        this.clickTimer();

        const params = {
          hi: id,
        };

        // 判斷是移除喜愛還是加入喜愛
        if (status) {
          // 喜愛的話
          params.st = status ? 1 : 0;
          await lioshutanApi.personnel.addVipLikeConsultant(params);
          this.consultantLike = consultantsListConstant.CONSULTANT_LIKE;
        } else {
          await lioshutanApi.personnel.cancelVipLikeConsultant(params);
          this.consultantLike = '';
        }

        params.index = this.index;
        // TODO: 先暫時拿掉
        // this.setLikeStatus(params);
      } else {
        console.log('賣購矇阿辣!');
      }
    },

    // 三秒內不給設定
    clickTimer() {
      this.likeEditable = false;
      setTimeout(() => {
        this.likeEditable = true;
      }, 1000 * 3);
    },

  },
};
</script>

<style lang="scss">
@import '@/components/vip/consultantList/detail/detail';
@import '@/styles/transition';
</style>
