const consultantDetailLang = {
  messages: {
    tw: {
      calendar: '行事曆',
      topic: '主題',
      score: '評分',
      from: '國籍',
      introduction: '自我介紹',
      suggestion: '給學習者的建議',
      experience: '已授課堂數',
      video: '影片',
      teach: '教學',
    },
    en: {
      calendar: 'Calendar',
      topic: 'Topic',
      score: 'Score',
      from: 'From',
      introduction: 'Self Introduction',
      suggestion: 'Learning Suggestion',
      experience: 'Teaching Experience',
      video: 'Video',
      teach: 'Teaching',
    },
  },
};

export {
  consultantDetailLang
};
